$(function () { //document ready
    //hornimenu
    $.fn.topMenuActive('#top-main-menu li a');
    //anchor
    $('.anchor-top').anchor();
    //resize
    $("#top-poptavka").fixedAndResize(1285);
    
    
    $('#breadcrumb li.first a').html('Úvodní stránka');
    $('.fancybox').fancybox();
    $('.fancybox.request').fancybox({
        'width': 700,
        'height': 450,
        'autoDimension': false
    });

    $(".fancybox.carRequest").fancybox({
        'width': 700,
        'height': 560,
        'autoDimension': false
    });

    // slideshow
    $('.home-slideshow').cycle({
        cleartype: true, 
        cleartypeNoBg: true,
        fx: 'fade',
        speed: $.browser.msie && $.browser.version<=8 ? 1 : 1000,
        timeout: 6000,
        pager: '#pager',
        next: '#next-slide',
        prev: '#prev-slide',
        pause:   1
    });

    // klienti slider
    $('.clients-slideshow').cycle({
        cleartype: true, 
        cleartypeNoBg: true,
        fx: 'fade',
        speed: $.browser.msie && $.browser.version<=8 ? 1 : 1000,
        timeout: 6000,
    });

    // sidebar slider
    $('.terms-slideshow').cycle({
        cleartype: true, 
        cleartypeNoBg: true,
        fx: 'scrollRight',
        speed: $.browser.msie && $.browser.version<=8 ? 1 : 300,
        timeout: 5000,
        next: '.next',
        prev: '.prev'
    });

    // faq
    $('.faq-box:first').addClass('act');
    $('.faq-box:first').removeClass('faq-box');
    $('.act').children('.faq-box-content').fadeIn();

    $('.faq-box h2 a').click(function(){
        $('.act').children('.faq-box-content').fadeOut();
        $('.act').addClass('faq-box');
        $('.act').removeClass('act');

        $(this).parent().parent().addClass('act');
        $(this).parent().parent().removeClass('faq-box');
        $(this).parent().parent().children('.faq-box-content').fadeIn();
        return false;
    });

    // newsletters
    $("#frmform-email.text").val("@");   
    $("#frmform-email.text").blur(function(){
        if($("#frmform-email.text").val() == ""){
            $("#frmform-email.text").val("@");  
        }   
    });

    // autopujcovna
    $('#frmautopujcovnaForm-pickupDate').datepicker();

    $('#frmautopujcovnaForm-button').click(function(e) {
        e.preventDefault();
        alert('prevented');
    })
});